<script>
export default {
  name: 'CoveLogo',
  props: { devise: { type: Object, default: () => ({}) } },
}
</script>

<template>
  <svg
    xmlns:dc="http://purl.org/dc/elements/1.1/"
    xmlns:cc="http://creativecommons.org/ns#"
    xmlns:rdf="http://www.w3.org/1999/02/22-rdf-syntax-ns#"
    xmlns:svg="http://www.w3.org/2000/svg"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 869.74664 410.54666"
  >
    <defs id="defs6" />
    <g id="g10" transform="matrix(1.3333333,0,0,-1.3333333,0,410.54667)">
      <g id="g12" transform="scale(0.1)">
        <path
          id="path14"
          style="
            fill: currentColor;
            fill-opacity: 1;
            fill-rule: nonzero;
            stroke: none;
          "
          d="m 2539.38,822.25 c -340.58,0 -588.16,357.32 -588.16,711.09 0,353.77 247.58,711 588.16,711 340.44,0 588.09,-357.23 588.09,-711 0,-353.77 -247.65,-711.09 -588.09,-711.09 z m 725.2,711.09 c 0,415.66 -322.8,738.48 -725.2,738.48 -402.49,0 -725.27,-322.82 -725.27,-738.48 0,-415.68 322.78,-738.531 725.27,-738.531 402.4,0 725.2,322.851 725.2,738.531"
        />
        <path
          id="path16"
          style="
            fill: currentColor;
            fill-opacity: 1;
            fill-rule: nonzero;
            stroke: none;
          "
          d="m 784.023,885.641 c -217.949,0 -400.8,94.558 -516.035,245.169 -1.73,2.23 -3.312,4.54 -5,6.79 -9.64,13 -18.965,26.24 -27.578,40.02 -2.226,3.51 -4.219,7.19 -6.347,10.74 -8.125,13.54 -15.918,27.26 -23.028,41.49 -1.387,2.83 -2.637,5.76 -3.984,8.62 -7.5,15.59 -14.563,31.44 -20.821,47.77 -0.246,0.68 -0.449,1.36 -0.695,2.03 -27.937,73.7 -43.484,154.42 -43.484,240.25 0,0.78 0.097,1.52 0.097,2.33 0,0.84 -0.097,1.64 -0.097,2.49 0,279.52 154.922,505.9 387.793,588.91 0.078,0.02 0.215,0.08 0.312,0.1 8.067,2.9 16.035,5.99 24.297,8.5 71.887,25.9 150.469,40.6 234.57,40.6 263.417,0 449.897,-137.91 537.647,-346.42 h 27.35 c -95.1,265.62 -322.36,446.79 -623.766,446.79 C 322.793,2271.82 0,1949 0,1533.34 0,1117.67 322.793,794.82 725.254,794.82 c 327.926,0 568.076,214.47 645.716,518.82 h -21.11 C 1279.49,1060.13 1080.46,885.641 784.023,885.641"
        />
        <path
          id="path18"
          style="
            fill: currentColor;
            fill-opacity: 1;
            fill-rule: nonzero;
            stroke: none;
          "
          d="m 5828.77,2244.34 c 349.38,0 557.17,-341.48 557.17,-710.08 H 5258.76 c 0,420.01 260.48,710.08 570.01,710.08 z m 75.04,-1353.918 c -121.56,0 -231.48,29.84 -325.13,81.84 -159.3,78.048 -273.81,226.588 -309.18,428.888 -6.27,64.88 31.64,105.66 96.42,105.66 h 1157.13 c -8.85,450.99 -278.61,765.01 -694.28,765.01 -389.14,0 -707.54,-322.82 -707.54,-738.48 0,-415.68 305.14,-738.52 738.52,-738.52 269.77,0 411.82,97.27 540,260.9 34.53,39.9 61.17,86.6 80.82,138.73 l -21.17,0.05 C 6349.54,1009.97 6148.85,890.422 5903.81,890.422"
        />
        <path
          id="path20"
          style="
            fill: currentColor;
            fill-opacity: 1;
            fill-rule: nonzero;
            stroke: none;
          "
          d="m 4315.01,1069.88 c -9.81,-21.37 -27.82,-37.89 -48.25,-37.89 -20.43,0 -40.56,19.88 -49.8,40.4 L 3681.67,2263 H 3540.14 L 4207.86,803.641 h 17.71 L 4858.01,2263 h -27.65 L 4315.01,1069.88"
        />
        <path
          id="path22"
          style="
            fill: currentColor;
            fill-opacity: 1;
            fill-rule: nonzero;
            stroke: none;
          "
          d="m 3700.73,2891.29 h -108.81 v 64.95 h 92.5 v 29.13 h -92.5 v 64 h 104.34 v 29.76 h -138.25 v -217.6 h 142.72 v 29.76"
        />
        <path
          id="path24"
          style="
            fill: currentColor;
            fill-opacity: 1;
            fill-rule: nonzero;
            stroke: none;
          "
          d="m 3311.63,2982.16 h -104.65 v 96.97 h -33.93 v -217.6 h 33.93 v 90.56 h 104.65 v -90.56 h 33.9 v 217.6 h -33.9 v -96.97"
        />
        <path
          id="path26"
          style="
            fill: currentColor;
            fill-opacity: 1;
            fill-rule: nonzero;
            stroke: none;
          "
          d="m 2878.34,2861.53 h 33.59 v 187.84 h 64.63 v 29.76 h -162.87 v -29.76 h 64.65 v -187.84"
        />
        <path
          id="path28"
          style="
            fill: currentColor;
            fill-opacity: 1;
            fill-rule: nonzero;
            stroke: none;
          "
          d="m 955.141,65.1914 28.379,67.4886 27.8,-67.4886 z M 982.961,199.32 898.438,2.46875 h 30.66 l 15.007,36.04295 h 78.255 l 15.05,-36.04295 h 32.06 L 984.66,199.32 h -1.699"
        />
        <path
          id="path30"
          style="
            fill: currentColor;
            fill-opacity: 1;
            fill-rule: nonzero;
            stroke: none;
          "
          d="m 2126.81,112.859 -13.35,5.961 c -20.97,9.321 -30.61,14.731 -30.61,30.039 0,13.34 9.93,22.422 26.37,22.422 15.34,0 25.85,-6.23 33.18,-18.73 l 22.96,15.051 c -12.19,20.386 -30.04,30.308 -56.72,30.308 -32.88,0 -55.29,-20.672 -55.29,-50.18 0,-30.101 19.58,-44.218 47.92,-56.7105 l 13.37,-5.9414 c 17,-7.6992 30.89,-14.4883 30.89,-32.6367 0,-15.6133 -13.6,-25.2812 -34.05,-25.2812 -19.84,0 -34.87,9.3789 -43.65,26.1289 L 2042.29,40.5117 C 2055.35,15.8398 2078.89,0 2111.76,0 c 36.6,0 63.83,17.8281 63.83,53.2891 0,32.6406 -19.57,46.5312 -48.78,59.5699"
        />
        <path
          id="path32"
          style="
            fill: currentColor;
            fill-opacity: 1;
            fill-rule: nonzero;
            stroke: none;
          "
          d="m 2306.82,86.7617 h 81.96 v 26.4103 h -81.96 v 55.277 h 92.45 v 26.91 H 2276.49 V 2.46875 h 126.77 V 29.4609 h -96.44 v 57.3008"
        />
        <path
          id="path34"
          style="
            fill: currentColor;
            fill-opacity: 1;
            fill-rule: nonzero;
            stroke: none;
          "
          d="m 1914.74,2.46875 h 30.37 V 195.34 h -30.37 V 2.46875"
        />
        <path
          id="path36"
          style="
            fill: currentColor;
            fill-opacity: 1;
            fill-rule: nonzero;
            stroke: none;
          "
          d="m 1447.73,65.1914 28.4,67.4886 27.77,-67.4886 z M 1475.55,199.32 1391.05,2.46875 h 30.62 l 15.03,36.04295 h 78.23 L 1530,2.46875 h 32.03 L 1477.25,199.32 h -1.7"
        />
        <path
          id="path38"
          style="
            fill: currentColor;
            fill-opacity: 1;
            fill-rule: nonzero;
            stroke: none;
          "
          d="m 1708.17,29.4414 h -22.42 V 168.449 h 22.42 c 49.91,0 71.75,-28.668 71.75,-69.4998 0,-40.8398 -21.84,-69.5078 -71.75,-69.5078 z m 1.7,165.9176 H 1655.4 V 2.48828 h 54.47 c 63.26,0 101.5,39.43362 101.5,96.46092 0,56.9918 -38.55,96.4098 -101.5,96.4098"
        />
        <path
          id="path40"
          style="
            fill: currentColor;
            fill-opacity: 1;
            fill-rule: nonzero;
            stroke: none;
          "
          d="m 1223.51,99.8203 h -30.35 v 68.6287 h 30.35 c 28.36,0 40.57,-11.078 40.57,-34.347 0,-22.922 -11.94,-34.2817 -40.57,-34.2817 z m 72.04,34.2817 c 0,36.597 -25.8,61.257 -70.88,61.257 h -61.84 V 2.48828 h 30.33 V 73.7188 h 31.51 3.66 l 43.1,-71.23052 h 33.19 l -46.54,76.63282 c 24.12,8.7773 37.47,28.0699 37.47,54.9809"
        />
        <path
          id="path42"
          style="
            fill: currentColor;
            fill-opacity: 1;
            fill-rule: nonzero;
            stroke: none;
          "
          d="m 760.859,97.5586 h -29.828 v 70.8904 h 29.828 c 28.36,0 40.536,-11.64 40.536,-35.48 0,-23.5 -11.903,-35.4104 -40.536,-35.4104 z m 1.118,97.8004 H 700.703 V 2.48828 h 30.328 V 70.5703 h 30.946 c 44.8,0 70.886,24.1602 70.886,62.3987 0,37.461 -25.816,62.39 -70.886,62.39"
        />
        <path
          id="path44"
          style="
            fill: currentColor;
            fill-opacity: 1;
            fill-rule: nonzero;
            stroke: none;
          "
          d="m 2663.67,2.76172 h 30.31 V 195.648 h -30.31 V 2.76172"
        />
        <path
          id="path46"
          style="
            fill: currentColor;
            fill-opacity: 1;
            fill-rule: nonzero;
            stroke: none;
          "
          d="M 3618.76,68.9492 3495.09,198.512 h -2.2 V 2.78125 h 28.33 V 129.57 L 3644.85,0 h 2.27 v 195.648 h -28.36 V 68.9492"
        />
        <path
          id="path48"
          style="
            fill: currentColor;
            fill-opacity: 1;
            fill-rule: nonzero;
            stroke: none;
          "
          d="m 2875.71,113.172 -13.36,5.937 c -20.98,9.321 -30.62,14.731 -30.62,30.063 0,13.328 9.93,22.418 26.39,22.418 15.31,0 25.8,-6.25 33.18,-18.75 l 22.99,15.051 c -12.19,20.39 -30.09,30.308 -56.73,30.308 -32.91,0 -55.33,-20.668 -55.33,-50.187 0,-30.071 19.58,-44.223 47.96,-56.7034 l 13.28,-5.9375 c 17.06,-7.6797 30.95,-14.4922 30.95,-32.6406 0,-15.6094 -13.62,-25.2617 -34.02,-25.2617 -19.88,0 -34.9,9.3593 -43.7,26.1132 l -25.51,-12.7812 c 13.04,-24.6719 36.58,-40.492206 69.5,-40.492206 36.59,0 63.79,17.800806 63.79,53.273406 0,32.6368 -19.56,46.527 -48.77,59.59"
        />
        <path
          id="path50"
          style="
            fill: currentColor;
            fill-opacity: 1;
            fill-rule: nonzero;
            stroke: none;
          "
          d="m 3812.66,29.75 h -22.42 v 139 h 22.42 c 49.89,0 71.73,-28.66 71.73,-69.5117 0,-40.8203 -21.84,-69.4883 -71.73,-69.4883 z m 1.7,165.898 h -54.45 V 2.78125 h 54.45 c 63.24,0 101.52,39.42965 101.52,96.45705 0,57.0117 -38.55,96.4097 -101.52,96.4097"
        />
        <path
          id="path52"
          style="
            fill: currentColor;
            fill-opacity: 1;
            fill-rule: nonzero;
            stroke: none;
          "
          d="m 3055.75,195.648 h -30.37 V 2.78125 h 115.41 V 29.75 h -85.04 v 165.898"
        />
        <path
          id="path54"
          style="
            fill: currentColor;
            fill-opacity: 1;
            fill-rule: nonzero;
            stroke: none;
          "
          d="m 3285.18,65.5 28.36,67.488 27.79,-67.488 z m 27.79,134.121 -84.55,-196.83975 h 30.68 l 15.01,36.01955 h 78.25 l 15.05,-36.01955 h 32.07 L 3314.66,199.621 h -1.69"
        />
        <path
          id="path56"
          style="
            fill: currentColor;
            fill-opacity: 1;
            fill-rule: nonzero;
            stroke: none;
          "
          d="m 4767.32,110.031 h -92.41 v 85.328 h -30.37 V 2.46875 h 30.37 V 83.0703 h 92.41 V 2.46875 h 30.33 V 195.359 h -30.33 v -85.328"
        />
        <path
          id="path58"
          style="
            fill: currentColor;
            fill-opacity: 1;
            fill-rule: nonzero;
            stroke: none;
          "
          d="m 5251,90.75 -94.11,108.57 h -1.43 V 2.46875 h 28.63 V 127.578 l 65.48,-78.5389 h 1.43 l 65.79,78.5389 V 2.46875 h 28.67 V 199.32 h -1.43 L 5251,90.75"
        />
        <path
          id="path60"
          style="
            fill: currentColor;
            fill-opacity: 1;
            fill-rule: nonzero;
            stroke: none;
          "
          d="m 5773.57,112.859 -13.34,5.961 c -21,9.321 -30.61,14.731 -30.61,30.039 0,13.34 9.92,22.422 26.36,22.422 15.32,0 25.82,-6.23 33.18,-18.73 l 22.96,15.051 c -12.19,20.386 -30.06,30.308 -56.68,30.308 -32.91,0 -55.33,-20.672 -55.33,-50.18 0,-30.101 19.56,-44.218 47.96,-56.7105 l 13.31,-5.9414 c 16.98,-7.6992 30.91,-14.4883 30.91,-32.6367 0,-15.6133 -13.62,-25.2812 -34.03,-25.2812 -19.84,0 -34.89,9.3789 -43.68,26.1289 L 5689.03,40.5117 C 5702.11,15.8398 5725.65,0 5758.53,0 c 36.61,0 63.82,17.8281 63.82,53.2891 0,32.6406 -19.6,46.5312 -48.78,59.5699"
        />
        <path
          id="path62"
          style="
            fill: currentColor;
            fill-opacity: 1;
            fill-rule: nonzero;
            stroke: none;
          "
          d="m 4236.76,28.5898 h -41.67 V 90.75 h 39.97 c 22.69,0 34.61,-12.1914 34.61,-31.2305 0,-18.1601 -10.8,-30.9297 -32.91,-30.9297 z m -41.67,140.7112 h 36.31 c 20.99,0 29.79,-10.813 29.79,-26.961 0,-15.899 -9.11,-27.199 -29.21,-27.199 h -36.89 z m 66.91,-64.66 c 18.71,5.699 28.94,21.211 28.94,40.539 0,28.09 -18.99,50.179 -57.3,50.179 h -68.61 V 2.48828 h 73.43 c 40.28,0 61.27,23.55082 61.27,54.77342 0,26.9492 -14.51,41.9766 -37.73,47.3793"
        />
        <path
          id="path64"
          style="
            fill: currentColor;
            fill-opacity: 1;
            fill-rule: nonzero;
            stroke: none;
          "
          d="m 4436.87,65.1914 28.36,67.4886 27.78,-67.4886 z M 4464.65,199.32 4380.15,2.46875 h 30.64 l 15.04,36.04295 h 78.26 l 15.05,-36.04295 h 32.02 L 4466.35,199.32 h -1.7"
        />
        <path
          id="path66"
          style="
            fill: currentColor;
            fill-opacity: 1;
            fill-rule: nonzero;
            stroke: none;
          "
          d="m 4947.75,65.1914 28.4,67.4886 27.78,-67.4886 z M 4975.57,199.32 4891.03,2.46875 h 30.64 l 15.08,36.04295 h 78.26 l 15.05,-36.04295 h 32.02 L 4977.27,199.32 h -1.7"
        />
        <path
          id="path68"
          style="
            fill: currentColor;
            fill-opacity: 1;
            fill-rule: nonzero;
            stroke: none;
          "
          d="m 5495.72,65.1914 28.32,67.4886 27.82,-67.4886 z M 5523.5,199.32 5438.99,2.46875 h 30.6 l 15.05,36.04295 h 78.29 l 15.01,-36.04295 h 32.03 L 5525.2,199.32 h -1.7"
        />
      </g>
    </g>
  </svg>
</template>
